<template>
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="fullPage"></loading>
    <form method="post" @submit.prevent="onSubmit('frmValuation')" data-vv-scope="frmValuation" novalidate="novalidate">
      <div class="row justify-content-md-center pt-2 px-2">

        <!-- Card Esquerda -->
        <div class="col-md-6 px-2">
          <div class="card card-fullheight">
            <div class="card-body p-2">
              <h5 class="box-title m-3">{{ $t('valuation.new') }}</h5>

              <div class="row custom-row">

                <!--  Motivo  -->
                <div class="col-12 form-group px-2">
                  <label class="small grey-label mb-0">Motivo</label>
                  <v-select class="grey-field" name="avaliacao.motivo" label="nome" :clearable="false"
                    :searchable="true" v-model="avaliacao.motivo" :options="reasonList"
                    @search="reasonFetchOptions"
                    v-validate="{ required: true }">
                    <template slot="no-options" slot-scope="{searching, search}">
                      <em v-if="!searching" style="opacity: 0.5;">
                        Digite para buscar um motivo
                      </em>
                      <em v-else style="opacity: 0.5;">
                        Nenhum motivo encontrado para {{ search }}
                      </em>
                    </template>
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ option.nome }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ option.nome }}
                      </div>
                    </template>
                  </v-select>
                  <transition name="slide" mode="">
                    <small v-if="errors.first('avaliacao.motivo')" class="royalc-error-field">{{ errors.first('avaliacao.motivo') }}</small>
                  </transition>
                </div>

                <!--  Origem  -->
                <div class="col-12 form-group px-2">
                  <label class="small grey-label mb-0">{{ labelOrigem }}</label>
                  <v-select class="grey-field" name="avaliacao.origem" label="nome" :clearable="false"
                    :searchable="true" v-model="avaliacao.origem" :options="originList"
                    @search="originFetchOptions"
                    v-validate="{ required: true }">
                    <template slot="no-options" slot-scope="{searching, search}">
                      <em v-if="!searching" style="opacity: 0.5;">
                        Digite para buscar uma pessoa
                      </em>
                      <em v-else style="opacity: 0.5;">
                        Nenhuma pessoa encontrada para {{ search }}
                      </em>
                    </template>
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ option.nome }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ option.nome }}
                      </div>
                    </template>
                  </v-select>
                  <transition name="slide" mode="">
                    <small v-if="errors.first('avaliacao.origem')" class="royalc-error-field">{{ errors.first('avaliacao.origem') }}</small>
                  </transition>
                </div>

                <!-- Descrição -->
                <div class="col-md-12 form-group px-2">
                  <label class="small grey-label mb-0">Descricao</label>
                  <custom-textarea
                    v-model="avaliacao.descricao"
                    name="avaliacao.descricao"
                    type="text"
                    rootClassName="md-form my-0"
                    inputClassName="md-form-control mt-0"
                    :rows="3"
                    v-validate="{ required: true }"
                    :error="errors.first('avaliacao.descricao')">
                  </custom-textarea>
                </div>

                <!--  Referencia  -->
                <div class="col-lg-8 form-group px-2 ">
                  <label class="small grey-label mb-0">Referência interna (Processo, desvio, orçamento, etc)</label>
                  <input type="text" class="md-form-control text-left"
                    v-model="avaliacao.referencia_interna"
                    :error="submitted ? errors.first('avaliacao.referencia_interna') : ''"
                    v-validate="{ required: false }">
                </div>

                <!--  Multa  -->
                <div class="col-lg-4 form-group px-2 ">
                  <label class="small grey-label mb-0">Receita/Prejuízo</label>
                  <input type="number" min="0" step=".01" class="md-form-control text-center"
                    v-model="avaliacao.valor"
                    :error="submitted ? errors.first('avaliacao.valor') : ''"
                    v-validate="{ required: false }">
                </div>

                <!--  Attach -->
                <div class="col-md-12 form-group px-2">
                  <custom-file-upload
                    v-model="avaliacao.arquivos"
                    v-show="!showProcessingMessage"
                    name="attach"
                    v-validate="{ required: false }"
                    :max-files="5"
                    :disabled="disabled"
                    :error="errors.first('avaliacao.arquivos')">
                  </custom-file-upload>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Card Direita -->
        <div class="col-md-6 px-2">
          <div class="card card-fullheight">
            <div class="card-body p-2">
              <h5 class="box-title m-3">{{ labelEsquerda }}</h5>
              <div class="row custom-row">
                <div class="col-12 form-group px-2">
                  <label class="small grey-label mb-0">Adicionar uma equipe inteira</label>
                  <v-select class="grey-field" name="selectedTeam" label="nome" :clearable="false"
                    :searchable="true" v-model="selectedTeam" :options="teamList"
                    @search="teamFetchOptions" @input="onSelectTeam">
                    <template slot="no-options" slot-scope="{searching, search}">
                      <em v-if="!searching" style="opacity: 0.5;">
                        Digite para buscar uma equipe
                      </em>
                      <em v-else style="opacity: 0.5;">
                        Nenhuma equipe encontrada para {{ search }}
                      </em>
                    </template>
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ option.nome }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ option.nome }}
                      </div>
                    </template>
                  </v-select>
                  <transition name="slide" mode="">
                    <small v-if="errors.first('selectedTeam')"
                      class="royalc-error-field">{{ errors.first('selectedTeam') }}</small>
                  </transition>
                </div>

                <div class="col-12 form-group px-2">
                  <label class="small grey-label mb-0">Pessoas específicas</label>
                  <custom-advanced-multiple-select
                    id-field="id"
                    labelField="nome"
                    name="avaliacao.destinos"
                    class="mx-0"
                    :enableStatus="1"
                    :no-options="'Buscar Funcionário'"
                    :tableFields="employeeFields"
                    :value="avaliacao.destinos"
                    :options="employeeList"
                    v-bind:fetchOptions="employeeFetchOptions"
                    @onRemoved="employeeRemoved"
                    @onInput="employeeSelected"
                    @rowClicked="employeeSelected"
                    @toggleStatus="toggleEmployeeStatus"
                    v-validate="{ required: true }">
                  </custom-advanced-multiple-select>
                  <transition name="slide" mode="">
                    <small v-if="errors.first('avaliacao.destinos')"
                      class="royalc-error-field">{{ errors.first('avaliacao.destinos') }}</small>
                  </transition>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Submit -->
        <div class="col-12 mb-5">
          <button type="button" class="btn btn-sm btn-outline-light pull-right ml-2"
            @click="onSave">Salvar</button>
          <!-- <button type="button" class="btn btn-link btn-sm pull-right"
            @click="onCancel">Cancelar</button> -->
          <!-- <button type="button" class="btn btn-link btn-sm mt-1 pull-right"
            @click="onShow">Console</button> -->
        </div>
      </div>
    </form>
  </div>
</template>

<script>
// Components
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
// eslint-disable-next-line
import { util } from '@/utils/utils.js'

// Form
import CustomFileUpload from '@/components/Forms/CustomFileUpload.vue'
import CustomAdvancedMultipleSelect from '@/components/Forms/CustomAdvancedMultipleSelect.vue'
import CustomTextarea from '@/components/Forms/CustomTextarea.vue'
// vue-select
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

// Services
import ValuationService from '@/services/ValuationService'
import ValuationReasonService from '@/services/ValuationReasonService'
import EmployeeService from '@/services/EmployeeService'
import PersonService from '@/services/PersonService'
import TeamService from '@/services/TeamService'

export default {
  name: 'ValuationForm',
  metaInfo () {
    return {
      titleTemplate: this.$i18n.t('valuation.new') + ' - %s'
    }
  },
  data () {
    return {
      isLoading: true,
      isEdit: false,
      disabled: false,
      formErrors: {},
      fullPage: true,
      submitted: false,
      delay: 400,
      //  Selected Values (Form)
      avaliacao: {
        motivo: null,
        origem: null,
        descricao: null,
        referencia_interna: null,
        valor: null,
        situacao: 0,
        dataCriacao: null,
        arquivos: [],
        destinos: []
      },
      reasonList: [],
      causeList: [],
      originList: [],
      employeeList: [],
      teamList: [],
      selectedTeam: null,
      showProcessingMessage: false,
      uploadEndpoint: process.env.VUE_APP_APIENDPOINT + 'v2/tariff/import/excel',
      employeeFields: [
        // {
        //   name: 'id',
        //   title: '#',
        //   sortField: 'id',
        //   formatter: (value) => this.$util.formatEmpty(value)
        // },
        {
          name: 'nome',
          title: this.$i18n.t('origin_destination.name'),
          sortField: 'nome',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'equipe',
          title: this.$i18n.t('valuation.team'),
          sortField: 'equipe',
          selectable: true,
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'protagonista',
          title: this.$i18n.t('valuation.protagonist'),
          sortField: 'protagonista',
          toggleable: true,
          formatter: (value) => this.$util.formatEmpty(value)
        }
      ]
    }
  },
  components: {
    Loading,
    vSelect,
    CustomFileUpload,
    CustomAdvancedMultipleSelect,
    CustomTextarea
  },
  // Apply filters to local filter
  beforeMount () {
  },
  mounted () {
    this.$store.dispatch('SetShowFilter', false)
    this.$store.dispatch('setShowSearch', false)
  },
  created () {
    let _this = this
    _this.isEdit = _this.$route.name === 'ValuationEdit'
    // let id = this.$route.params.id

    let filters = {
      'searchTerm': '',
      'per_page': 500,
      'includeAlan': false
    }

    ValuationReasonService.getReasonOptions(filters).then(res => {
      this.reasonList = res.data.data
    })

    PersonService.getPeopleOptions(filters).then(res => {
      _this.originList = res.data.data
    })

    TeamService.getTeamOptions(filters).then(res => {
      this.teamList = res.data.data
    })

    _this.isLoading = false
  },
  methods: {
    displayErrors (errors) {
      let formatedErrors = util.getFormErrors(errors)
      this.formErrors = formatedErrors
    },
    showNumber (number) {
      return util.numberFormat(number, 2, ',', '.')
    },
    mountLink (itemName, object) {
      return this.$router.resolve({ name: itemName, params: { id_Valuation: object.id } }).href
    },
    /**
     * Lista de origens do elogio/reclamação.
     *
     * @param search  {String}   Current search text
     * @param loading {Function} Toggle loading class
     */
    reasonFetchOptions (search, loading) {
      if (search.length > 0) {
        let me = this
        window.clearTimeout(this.timer)

        this.timer = window.setTimeout(() => {
          loading(true)

          let filters = {
            'searchTerm': search,
            'includeAlan': false
          }

          ValuationReasonService.getReasonOptions(filters).then(res => {
            me.reasonList = res.data.data
            loading(false)
          })
        }, this.delay)
      }
    },
    /**
     * Lista de origens do elogio/reclamação.
     *
     * @param search  {String}   Current search text
     * @param loading {Function} Toggle loading class
     */
    teamFetchOptions (search, loading) {
      if (search.length > 0) {
        let me = this
        window.clearTimeout(this.timer)

        this.timer = window.setTimeout(() => {
          loading(true)

          let filters = {
            'searchTerm': search,
            'includeAlan': false
          }

          TeamService.getTeamOptions(filters).then(res => {
            me.teamList = res.data.data
            loading(false)
          })
        }, this.delay)
      }
    },
    onSelectTeam () {
      if (!this.isset(this.selectedTeam)) {
        return
      }

      let currentTeam = {
        id: this.selectedTeam.id,
        nome: this.selectedTeam.nome
      }

      this.selectedTeam.funcionarios.forEach(employee => {
        this.addEmployeeToSelected(employee.funcionario, currentTeam)
      })

      this.selectedTeam = null
    },
    addEmployeeToSelected (employee, currentTeam) {
      let teamOptions = []
      teamOptions.push(currentTeam)

      employee.equipes.forEach(x => {
        if (x) {
          teamOptions.push({
            id: x.equipe.id,
            nome: x.equipe.nome
          })
        }
      })

      if (!this.isIncluded(employee)) {
        this.avaliacao.destinos.push({
          id: employee.id,
          nome: employee.id_pessoa.nome,
          id_employee: employee.id_table_origin,
          protagonista: 0,
          equipe: {
            selected: currentTeam,
            options: teamOptions
          },
          status: 1
        })
      }
    },
    isIncluded (employee) {
      let selected = this.avaliacao.destinos.find((x) => {
        return x.id === employee.id
      })

      if (selected !== undefined) {
        return true
      }

      return false
    },
    /**
     * Lista de origens do elogio/reclamação.
     *
     * @param search  {String}   Current search text
     * @param loading {Function} Toggle loading class
     */
    originFetchOptions (search, loading) {
      if (search.length > 0) {
        let me = this
        window.clearTimeout(this.timer)

        this.timer = window.setTimeout(() => {
          loading(true)

          let filters = {
            'searchTerm': search,
            'includeAlan': false
          }

          PersonService.getPeopleOptions(filters).then(res => {
            me.originList = res.data.data
            loading(false)
          })
        }, this.delay)
      }
    },
    /**
     * Lista de destinos para o elogio/reclamação.
     *
     * @param search  {String}   Current search text
     * @param loading {Function} Toggle loading class
     */
    employeeFetchOptions (search, loading) {
      if (search.length > 0) {
        // let me = this
        window.clearTimeout(this.timer)

        this.timer = window.setTimeout(() => {
          loading(true)
          let filters = {
            'searchTerm': search,
            'includeAlan': true
          }

          EmployeeService.getEmployeeOptions(filters).then(res => {
            let records = res.data.data
            this.employeeList = records.map((record) => {
              let equipes = record.equipes.map(x => {
                return {
                  id: x.equipe.id,
                  nome: x.equipe.nome
                }
              })

              let selected = {
                id: null,
                nome: 'Sem opções'
              }

              if (equipes.length > 0) {
                selected = equipes[0]
              }

              return {
                id: record.id,
                id_employee: record.id_employee,
                nome: record.id_pessoa.nome,
                protagonista: 1,
                equipe: {
                  selected: selected,
                  options: equipes
                },
                status: 1
              }
            })
            loading(false)
          })
        }, this.delay)
      }
    },
    employeeRemoved (e) {
      // console.log(e)
    },
    employeeSelected (e) {
      // console.log(e)
    },
    toggleEmployeeStatus (val) {
      let employee = {
        id: val.id,
        id_table_origin: val.id_table_origin,
        customer: val.cliente_id
      }

      if (val.status === 1) {
        if (!this.isIncluded(employee)) {
          this.task.contacts.push(employee)
        }
      } else {
        if (this.isIncluded(employee)) {
          let index = this.avaliacao.destinos.findIndex(function (element) {
            return element.id === employee.id
          })
          // remove element from selected options
          this.avaliacao.destinos.splice(index, 1)
        }
      }
    },
    onCancel (e) {
      var _this = this
      _this.$router.push({ name: 'ValuationIndex' })
    },
    onSave (e) {
      var _this = this
      this.submitted = true
      _this.$validator.validateAll('frmValuation').then((valid) => {
        if (valid) {
          let formData = new FormData()
          formData.append('texto', this.avaliacao.descricao)
          formData.append('motivo', this.avaliacao.motivo.id)
          formData.append('origem', this.avaliacao.origem.id)
          formData.append('informante', this.user)
          let alvos = this.avaliacao.destinos.map(x => {
            return {
              id: x.id,
              protagonista: x.protagonista,
              equipe: x.equipe.selected.id
            }
          })
          formData.append('alvos', JSON.stringify(alvos))
          if (this.avaliacao.referencia_interna) {
            formData.append('referenciaInterna', this.avaliacao.referencia_interna)
          }
          formData.append('valor', this.avaliacao.valor)
          formData.append('arquivos', JSON.stringify(this.avaliacao.arquivos))
          this.isLoading = true
          ValuationService.post(formData).then(dataJson => {
            this.isLoading = false
            let msg = `<h4>Sua mensagem foi enviada com sucesso!</h4>`
            global.instanceApp.$alertSwal.modalAlert(null, msg, 'success', '').then((result) => {
              this.$router.push({ name: 'Dashboard' })
            })
            this.isLoading = false
            this.isPageLoading = false
            this.onClearForm()
          }).catch(err => {
            if (err.code === 400) {
              _this.displayErrors(err.errors)
            }
            _this.isLoading = false
          })
        }
      })
    },
    onClearForm () {
      this.avaliacao = {
        motivo: null,
        origem: null,
        descricao: null,
        referencia_interna: null,
        valor: null,
        arquivos: [],
        destinos: []
      }
      this.onClearImage()
      this.submitted = false
    },
    onClearImage () {
      this.$refs.labelfiles.innerText = 'Clique aqui para adicionar um arquivo.'
      this.$refs.spanfiles.innerText = ``
      this.$refs.files.value = null
    },
    onShow () {
    },
    isset (variable) {
      return variable !== 'undefined' && variable != null && variable !== ''
    }
  },
  computed: {
    user () {
      return this.$store.getters.userInfo || null
    },
    labelEsquerda () {
      return this.avaliacao.motivo ? this.avaliacao.motivo.tipo.concat(' ', 'para') : 'Avaliação para'
    },
    labelOrigem () {
      return this.avaliacao.motivo ? 'Origem'.concat(' do(a) ', this.avaliacao.motivo.tipo.toLowerCase()) : 'Origem'
    }
  }
}
</script>

<style scoped>
  .swal2-popup {
    width: inherit !important;
  }

  .card-body {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .md-form-control {
    height: 30px;
  }

  .v-select.md-form-control {
    height: 35px;

  }

  td.editable {
    padding: 0;
    vertical-align: middle;
  }

  td.editable input {
    background-color: inherit;
    border: 0;
    width: 100%;
    min-height: 33px;
    box-sizing: border-box;
  }

  .card-subtitle {
    margin-top: 15px;
  }

</style>
